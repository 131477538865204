<template>
  <v-col cols="12">
    <dynamic-data-table
      :table-headers="headers"
      :table-records="tokens"
      :is-loading="tokenStatus === 'loading'"
      table-title="Tokens"
      :can-delete="true"
      @deleteItem="displayDeleteDialog"
      @reloadData="fetchTokens"
    >
      <template #table-actions>
        <v-dialog key="add-token" v-model="tokenDialog" max-width="500px">
          <template #activator="{ on }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-icon dense class="ant-icon" v-on="{ ...on, ...onTooltip }">
                  mdi-plus
                </v-icon>
              </template>
              <span>New token</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              {{ $t('system.profile.tokens.addNewToken') }}
            </v-card-title>
            <v-divider />
            <v-form ref="form" @submit.prevent>
              <div class="px-10 pb-5">
                <ant-input label="Name" :is-optional="false">
                  <template #input-field>
                    <v-text-field
                      v-model="token.app_name"
                      :rules="[rules.required]"
                      :label="$t('system.profile.tokens.appName')"
                      hide-details
                      dense
                      filled
                      single-line
                      @input="error = false"
                    />
                  </template>
                </ant-input>
              </div>
              <v-card-actions class="ant-border-top ant-dialog-actions-bg">
                <v-spacer />
                <v-btn
                  color="error"
                  text
                  small
                  @click="
                    tokenDialog = false;
                    token = Object.assign({}, {});
                  "
                >
                  {{ $t('general.cancel') }}
                </v-btn>
                <v-btn small elevation="0" color="primary" @click="createToken">
                  {{ $t('general.save') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="tokenDeleteDialog"
      :title="
        $t('system.profile.tokens.notifications.confirmRemoveToken', {
          token: tokenToDelete.app_name,
        })
      "
      @deleteAction="deleteToken"
      @closeDialog="
        tokenDeleteDialog = false;
        tokenToDelete = Object.assign({}, {});
      "
    />
  </v-col>
</template>

<script>
import tokensStore from '../../store/tokens';
import { mapGetters } from 'vuex';
import DeleteDialog from '../DeleteDialog';
import DynamicDataTable from '@/components/DynamicDataTable';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'Tokens',
  components: { AntInput, DynamicDataTable, DeleteDialog },
  data() {
    return {
      headers: [
        {
          text: this.$t('system.profile.tokens.table.name'),
          value: 'app_name',
        },
        {
          text: this.$t('system.profile.tokens.table.cId'),
          value: 'client_id',
        },
        {
          text: this.$t('system.profile.tokens.table.cSecret'),
          value: 'client_secret',
        },
        { text: '', value: 'actions', width: '1%', sortable: false },
      ],
      rules: {
        required: (value) => !!value || 'Required.',
      },
      tokenDialog: false,
      tokenDeleteDialog: false,
      tokenToDelete: {},
      token: {},
    };
  },
  computed: {
    ...mapGetters(['tokens', 'tokenStatus']),
  },
  mounted() {
    if (this.$route.query.status !== 'reset') {
      this.fetchTokens();
    }
  },
  methods: {
    fetchTokens() {
      this.$store.dispatch('fetchTokens');
    },
    displayDeleteDialog(item) {
      this.tokenToDelete = item;
      this.tokenDeleteDialog = true;
    },
    deleteToken() {
      this.$store.dispatch('deleteToken', this.tokenToDelete).then(() => {
        this.tokenDeleteDialog = false;
        this.tokenToDelete = Object.assign({}, {});
      });
    },
    createToken() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('createToken', this.token).then(() => {
          this.tokenDialog = false;
          this.token = Object.assign({}, {});
          this.$refs.form.reset();
        });
      }
    },
  },
};
</script>

<style scoped></style>
