<template>
  <div class="d-flex flex-column ant-glass-background radius-0 pa-5">
    <div class="d-flex">
      <edit-profile v-if="!isReset" class="flex-1 pa-2" />
      <v-divider vertical class="mx-5" />
      <edit-password class="flex-1 pa-2" />
    </div>
    <div v-if="!isReset" class="d-flex pa-4" style="min-height: 100px">
      <div class="d-flex flex-column">
        <div class="tfa-header">Two-Factor Authentication</div>
        <div class="tfa-sub-header">
          Increase your account's security by enabling Two-Factor Authentication
          (2FA)
        </div>
      </div>
      <v-divider vertical class="mx-5" />
      <div class="d-flex flex-column">
        <div class="d-flex align-center flex-1">
          Status
          <v-icon
            class="ml-2"
            :color="authenticatedUser.two_factor_enabled ? 'success' : 'error'"
          >
            {{
              authenticatedUser.two_factor_enabled
                ? 'mdi-check-circle'
                : 'mdi-close-circle'
            }}
          </v-icon>
        </div>
        <v-btn
          color="primary"
          small
          elevation="0"
          @click="enableDisableTwoFactor"
        >
          {{ authenticatedUser.two_factor_enabled ? 'disable' : 'enable' }}
        </v-btn>
        <v-dialog
          key="eneble-2f-auth"
          v-model="enableDialog.display"
          max-width="500px"
          width="unset"
          @keydown.esc="closeDialogs"
          @click:outside="closeDialogs"
        >
          <v-card>
            <v-card-title> Enable Two-Factor Authentication </v-card-title>
            <v-card-text>
              <v-card-subtitle>
                Use a one-time password authenticator on your mobile device or
                computer to enable two-factor authentication (2FA)
              </v-card-subtitle>
              <div class="d-flex">
                <div v-html="enableDialog.url" />
                <v-text-field
                  v-model="enableDialog.twoFactorCode"
                  label="Two factor token"
                  type="number"
                />
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="red" text @click="closeDialogs"> Cancel </v-btn>
              <v-btn
                color="primary"
                :disabled="enableDialog.twoFactorCode.length !== 6"
                @click="enableTwoFactor"
              >
                enable
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          key="disable-2f-auth"
          v-model="disableDialog.display"
          max-width="500px"
          width="unset"
          @keydown.esc="closeDialogs"
          @click:outside="closeDialogs"
        >
          <v-card>
            <v-card-title> Disable Two-Factor Authentication </v-card-title>
            <v-card-text>
              <v-card-subtitle>
                Are you sure you want to disable 2FA-Authentication? Disabling
                2FA will result in a less secure account. If you still want to
                disable enter your 2FA code below
              </v-card-subtitle>
              <div class="d-flex">
                <v-text-field
                  v-model="disableDialog.twoFactorCode"
                  label="Two factor token"
                  type="number"
                />
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="red" text @click="closeDialogs"> Cancel </v-btn>
              <v-btn
                color="primary"
                :disabled="disableDialog.twoFactorCode.length !== 6"
                @click="disableTwoFactor"
              >
                disable
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <v-divider class="my-2" />
    <tokens v-if="!isReset" style="background-color: white" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  disableTwoFactor,
  enableTwoFactor,
  generateTwoFactor,
} from '@/services/api/user.api';

export default {
  name: 'Settings',
  components: {
    EditProfile: () =>
      import(
        /* webpackChunkName: "Settings" */ '../components/Settings/EditProfile'
      ),
    EditPassword: () =>
      import(
        /* webpackChunkName: "Settings" */ '../components/Settings/EditPassword'
      ),
    Tokens: () =>
      import(
        /* webpackChunkName: "Settings" */ '../components/Settings/Tokens'
      ),
  },
  data: () => {
    return {
      bannerLogo: true,
      enableDialog: {
        display: false,
        url: undefined,
        twoFactorCode: '',
      },
      disableDialog: {
        display: false,
        twoFactorCode: '',
      },
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser']),
    isReset() {
      return this.$route.query.status === 'reset';
    },
  },
  mounted() {
    if (this.isReset) {
      this.$store.commit('showNotification', {
        content:
          'Your password is expired, please reset password before accessing ANT',
        color: 'info',
      });
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('service-worker.js')
        // .then((reg) => {
        //   console.log("Registration succesful, scope: " + reg.scope);
        // })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log('beforeinstallprompt Event fired 1');
      e.preventDefault();
      console.log(e);
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e.originalEvent;
    });
    window.addEventListener('appinstalled', () => {
      console.log('beforeinstallprompt Event fired 2');
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      //Cookies?
      this.deferredPrompt = null;
    },
    async install() {
      console.log('beforeinstallprompt Event fired 3');
      console.log(this.deferredPrompt);
      this.deferredPrompt.prompt();
    },
    enableDisableTwoFactor() {
      let enabled = this.authenticatedUser.two_factor_enabled;
      if (enabled) {
        // disable two factor
        this.disableDialog.display = true;
      } else {
        // enable two factor
        generateTwoFactor()
          .then((response) => {
            let decoded = decodeURI(response.url);
            let element = decoded.match(/<svg.*<\/svg>/)[0];
            if (element.length > 0) {
              // successfully fetched qrcode
              this.enableDialog.display = true;
              this.enableDialog.url = element;
            } else {
              this.$store.commit('showNotification', {
                content: 'QR code could not be generated, please try again',
                color: 'error',
              });
            }
          })
          .catch((error) => {
            this.$store.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
          });
      }
    },
    enableTwoFactor() {
      enableTwoFactor(this.enableDialog.twoFactorCode)
        .then((response) => {
          this.$store.commit('enable_two_factor');
          this.closeDialogs();
        })
        .catch((error) => {
          this.$store.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    },
    disableTwoFactor() {
      disableTwoFactor(this.disableDialog.twoFactorCode)
        .then((response) => {
          this.$store.commit('disable_two_factor');
          this.closeDialogs();
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    },
    closeDialogs() {
      this.enableDialog = {
        display: false,
        url: undefined,
        twoFactorCode: '',
      };
      this.disableDialog = {
        display: false,
        twoFactorCode: '',
      };
    },
  },
};
</script>

<style scoped lang="scss">
.tfa-header {
  font-size: 18px;
  font-weight: bold;
}

.tfa-sub-header {
  font-size: 12px;
}
</style>
