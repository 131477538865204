<template>
  <v-form
    ref="changepasswordForm"
    class="d-flex flex-column fill-height justify-space-between"
    @submit.prevent="onSubmit"
  >
    <div>
      <div
        v-if="passwordExpires < 7 && passwordExpires > 0"
        class="d-flex font-italic mb-2"
      >
        <v-icon color="warning"> mdi-exclamation </v-icon>
        {{
          $t('system.profile.notifications.pwExpires', {
            passwordExpires,
          })
        }}
      </div>
      <ant-input
        :label="`${$t('system.profile.currentPW')}`"
        :is-optional="false"
        style="margin-top: 0 !important"
      >
        <template #input-field>
          <v-text-field
            v-model="currentPassword"
            :label="$t('system.profile.currentPW')"
            class="mb-2"
            :placeholder="$t('system.profile.EnterCurrentPW')"
            :rules="[rules.required]"
            :append-icon="
              currentPasswordFieldType === 'password'
                ? 'mdi-eye-outline'
                : 'mdi-eye-off-outline'
            "
            :type="currentPasswordFieldType"
            dense
            filled
            single-line
            @click:append="
              currentPasswordFieldType === 'password'
                ? (currentPasswordFieldType = 'text')
                : (currentPasswordFieldType = 'password')
            "
          />
        </template>
      </ant-input>
      <ant-input :label="`${$t('system.profile.newPW')}`" :is-optional="false">
        <template #input-field>
          <v-text-field
            v-model="newPassword"
            :label="$t('system.profile.newPW')"
            class="mb-2"
            :placeholder="$t('system.profile.enterNewPW')"
            :rules="[rules.required, rules.password, passwordUniqueRule]"
            :append-icon="
              newPasswordFieldType === 'password'
                ? 'mdi-eye-outline'
                : 'mdi-eye-off-outline'
            "
            :type="newPasswordFieldType"
            dense
            filled
            single-line
            @click:append="
              newPasswordFieldType === 'password'
                ? (newPasswordFieldType = 'text')
                : (newPasswordFieldType = 'password')
            "
          />
        </template>
      </ant-input>

      <ant-input
        :label="`${$t('system.profile.repeatNewPW')}`"
        :is-optional="false"
        style="margin-top: 0 !important"
      >
        <template #input-field>
          <v-text-field
            v-model="confirmNewPassword"
            :label="$t('system.profile.repeatNewPW')"
            class="mb-2"
            :placeholder="$t('system.profile.enterNewPW')"
            :rules="[rules.required, passwordConfirmationRule]"
            :append-icon="
              confirmPasswordFieldType === 'password'
                ? 'mdi-eye-outline'
                : 'mdi-eye-off-outline'
            "
            :type="confirmPasswordFieldType"
            dense
            filled
            single-line
            @click:append="
              confirmPasswordFieldType === 'password'
                ? (confirmPasswordFieldType = 'text')
                : (confirmPasswordFieldType = 'password')
            "
          />
        </template>
      </ant-input>
    </div>

    <v-btn type="submit" color="primary" width="100%" elevation="0">
      {{ $t('general.save') }}
    </v-btn>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'EditPassword',
  components: { AntInput },
  data() {
    return {
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      rules: {
        required: (value) => !!value || 'Required',
        password: (value) => {
          const pattern =
            /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?!.*\s).{8,}$/;
          return (
            pattern.test(value) ||
            this.$t('system.profile.notifications.pwChallenge')
          );
        },
      },
      currentPasswordFieldType: 'password',
      newPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser']),
    passwordExpires() {
      return this.authenticatedUser.password_expires_in;
    },
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmNewPassword ||
        this.$t('system.profile.notifications.match');
    },
    passwordUniqueRule() {
      return () =>
        this.currentPassword !== this.newPassword ||
        this.$t('system.profile.notifications.differentPW');
    },
    isReset() {
      return this.$route.query.status === 'reset';
    },
  },
  methods: {
    onSubmit() {
      if (this.$refs.changepasswordForm.validate()) {
        this.$store
          .dispatch('changePassword', {
            currentpassword: this.currentPassword,
            newpassword: this.newPassword,
          })
          .then(() => {
            this.loading = false;
            this.success = true;
            this.currentPassword = null;
            this.newPassword = null;
            this.confirmNewPassword = null;
            if (this.isReset) {
              this.$router.push('/dashboard');
            }
          })
          .catch((response) => {
            this.error = true;
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
