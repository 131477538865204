import httpClient from '../httpClient';

const getCompanies = () => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/companies`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getCompany = (companyId, projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/company/${companyId}`, {
        params: {
          'project[id]': projectId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateCompany = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/company/${data.id}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteCompany = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/company/${data.id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createCompany = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/company`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data.message);
      });
  });
};

export {
  getCompanies,
  getCompany,
  updateCompany,
  deleteCompany,
  createCompany,
};
