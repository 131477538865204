<template>
  <div v-if="authenticatedUser" class="profile-container">
    <div class="profile-image">
      <v-avatar
        color="primary"
        size="140"
        class="mr-2 d-flex align-center"
        style="cursor: pointer"
        @click="changeProfilePicture"
      >
        <v-img
          v-if="profileImage || authenticatedUser.photo"
          :src="getProfileImageBase64()"
        />
        <span
          v-else
          class="white--text text-h6"
          style="font-size: 50px !important; user-select: none"
        >
          {{
            authenticatedUser.firstname.charAt(0) +
            authenticatedUser.lastname.charAt(0)
          }}
        </span>
      </v-avatar>
    </div>
    <div class="profile-information">
      <div>
        <ant-input label="Firstname" :is-optional="false" style="margin-top: 0 !important;">
          <template #input-field>
            <v-text-field
              v-model="authenticatedUser.firstname"
              hide-details
              dense
              filled
              single-line
              class="mb-2"
              :label="$t('system.profile.firstName')"
              type="text"
              :placeholder="$t('system.profile.descFirst')"
            />
          </template>
        </ant-input>
        <ant-input label="Lastname" :is-optional="false">
          <template #input-field>
            <v-text-field
              v-model="authenticatedUser.lastname"
              hide-details
              dense
              filled
              single-line
              :label="$t('system.profile.lastName')"
              type="text"
              class="mb-2"
              :placeholder="$t('system.profile.descLast')"
            />
          </template>
        </ant-input>

        <ant-input label="Email" :is-optional="false">
          <template #input-field>
            <v-text-field
              v-model="authenticatedUser.email"
              hide-details
              dense
              filled
              single-line
              :label="$t('system.profile.email')"
              type="text"
              class="mb-2"
              :placeholder="$t('system.profile.descEmail')"
            />
          </template>
        </ant-input>

        <div class="d-flex align-center">
          <ant-input label="Company" :is-optional="false">
            <template #input-field>
              <v-dialog
                key="update-user-company"
                v-model="companyDialog"
                max-width="500px"
                @click:outside="closeCompanyDialog"
                @keydown.esc="closeCompanyDialog"
              >
                <template #activator="{ on, attrs }">
                  <v-chip
                    label
                    v-bind="attrs"
                    class="mt-2"
                    v-on="on"
                    @click="fetchCompanies"
                  >
                    {{ authenticatedUser.company.name }}
                  </v-chip>
                </template>
                <v-card :loading="companiesLoading">
                  <v-card-title>Select Company</v-card-title>
                  <v-card-text>
                    <v-combobox
                      v-model="selectedCompany"
                      :items="companies"
                      item-value="id"
                      item-text="name"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="red" text @click="closeCompanyDialog">
                      Cancel
                    </v-btn>
                    <v-btn color="primary" @click="updateUserCompany">
                      update
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </ant-input>

          <ant-input label="Language" class="ml-10" :is-optional="false">
            <template #input-field>
              <v-select
                v-model="authenticatedUser.language"
                :items="languageTypes"
                hide-details
                dense
                filled
                single-line
              >
                <template #item="{ item }">
                  {{ item }} | {{ languageWrittenOut(item) }}
                </template>
              </v-select>
            </template>
          </ant-input>
        </div>

        <div class="d-flex align-center my-2">
          <v-switch
            v-model="authenticatedUser.task_daily_digest"
            label="Receive task daily digest"
            class="my-2 pa-0"
            dense
            hide-details
          />
          <v-tooltip bottom max-width="200px">
            <template #activator="{ on, attrs }">
              <v-icon class="ml-2" v-on="on">mdi-information-outline</v-icon>
            </template>
            <span>{{ $t('system.profile.taskDigestInfo') }}</span>
          </v-tooltip>
        </div>
      </div>

      <div>
        <v-btn
          color="primary"
          width="100%"
          elevation="0"
          :loading="$wait.is('user.update')"
          @click="updateAuthenticatedUser"
        >
          {{ $t('general.save') }}
        </v-btn>

        <v-file-input
          id="profileImageUpload"
          v-model="profileImage"
          style="
            position: absolute;
            left: -9999px;
            opacity: 0;
            height: 0;
            width: 0;
          "
          accept="image/png, image/jpeg, image/bmp"
          hide-input
          label="Project image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCompanies } from '@/services/api/companies.api';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'EditProfile',
  components: { AntInput },
  data() {
    return {
      profileImage: null,
      languageTypes: ['en', 'nl', 'de'],
      companyDialog: false,
      companies: [],
      companiesLoading: false,
      selectedCompany: undefined,
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser']),
  },
  mounted() {
    this.$store.dispatch('fetchAuthenticatedUser');
  },
  methods: {
    closeCompanyDialog() {
      this.selectedCompany = undefined;
      this.companyDialog = false;
    },
    languageWrittenOut(abbreviation) {
      switch (abbreviation) {
        case 'en':
          return 'English';
        case 'nl':
          return 'Dutch';
        case 'de':
          return 'German';
      }
    },
    changeProfilePicture() {
      document.getElementById('profileImageUpload').click();
    },
    getProfileImageBase64() {
      if (this.authenticatedUser.photo !== null) {
        if (this.profileImage !== null) {
          if (this.profileImage instanceof File) {
            return URL.createObjectURL(this.profileImage);
          } else {
            return (
              'data:' +
              this.authenticatedUser.photo.mimetype +
              ';base64,' +
              this.authenticatedUser.photo.file
            );
          }
        } else {
          return (
            'data:' +
            this.authenticatedUser.photo.mimetype +
            ';base64,' +
            this.authenticatedUser.photo.file
          );
        }
      } else {
        if (this.profileImage !== null) {
          if (this.profileImage instanceof File) {
            return URL.createObjectURL(this.profileImage);
          }
        }
      }
      return '';
    },

    handleFile(file) {
      if (typeof file === 'object') {
        const selectedFile = file;
        let apiObject = Object.assign(
          {},
          {
            name: String,
            extension: String,
            data: String,
          }
        );

        apiObject.name = this.getFileName(selectedFile.name);
        apiObject.extension = this.getFileExtension(selectedFile.name);

        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = (e) => {
            const re = /(?:base64,(.*))?$/;
            apiObject.data = re.exec(e.target.result)[1];

            resolve(apiObject);
          };
        });
      }
    },

    getFileExtension(fileName) {
      const re = /(?:\.([^.]+))?$/;

      return re.exec(fileName)[1];
    },
    getFileName(fileName) {
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      return fileName.replace(extension, '');
    },

    async updateAuthenticatedUser() {
      if (this.profileImage !== null) {
        let photo = await this.handleFile(this.profileImage);

        await this.$store
          .dispatch('updateAuthenticatedUser', photo)
          .then(() => {
            this.profileImage = null;
          });
      } else {
        await this.$store.dispatch('updateAuthenticatedUser');
      }
    },
    fetchCompanies() {
      this.companiesLoading = true;
      this.selectedCompany = this.authenticatedUser.company;
      getCompanies().then((companies) => {
        this.companies = companies;
        this.companiesLoading = false;
      });
    },
    updateUserCompany() {
      this.authenticatedUser.company = this.selectedCompany;
      this.closeCompanyDialog();
    },
  },
};
</script>

<style scoped lang="scss">
.profile-container {
  display: flex;
  height: 100%;

  .profile-image {
    height: 100%;
    width: 150px;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .profile-information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
}
</style>
